import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  CreateCheckoutSessionUseCaseInput,
  CreateCheckoutSessionUseCaseOutput,
  ErrorCode,
  IAppErrorFactory,
  ICreateCheckoutSessionUseCase,
  ISubscriptionsService,
} from '../../types'

@injectable()
export default class CreateCheckoutSessionInteractor implements ICreateCheckoutSessionUseCase {
  @inject(symbols.ISubscriptionsService) private subscriptionsService: ISubscriptionsService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: CreateCheckoutSessionUseCaseInput): Promise<CreateCheckoutSessionUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const CheckoutSessionBase = await this.subscriptionsService.createCheckoutSession(input)
      output.sessionUrl = CheckoutSessionBase
    } catch (e) {
      const errorCode = ErrorCode.STRIPE_CURRENCY_MISMATCH
      let messageForUI = 'チェックアウトセッションの作成に失敗しました'
      if (e instanceof Error && e?.message.includes(errorCode)) {
        messageForUI = 'サブスクリプションの通貨が一致しません。'
      }
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI,
        extra: {
          useCaseInput: input,
        },
      })
    }

    return output
  }

  private getDefaultOutputData(): CreateCheckoutSessionUseCaseOutput {
    return {
      sessionUrl: null,
      error: null,
    }
  }
}
