import { inject, injectable } from 'inversify'
import {
  ConfirmRegistrationWithTokenUseCaseInput,
  ConfirmRegistrationWithTokenUseCaseOutput,
  IAppCredentials,
  IAppErrorFactory,
  IAuthService,
  IConfirmRegistrationWithTokenUseCase,
} from '@/types'
import symbols from '@/symbols'
import ExpectedError from '@/errors/ExpectedError'

@injectable()
export default class ConfirmRegistrationWithTokenInteractor implements IConfirmRegistrationWithTokenUseCase {
  @inject(symbols.IAuthService) private authService: IAuthService

  @inject(symbols.IAppCredentials) private credentials: IAppCredentials

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: ConfirmRegistrationWithTokenUseCaseInput): Promise<ConfirmRegistrationWithTokenUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const credentials = await this.authService.confirmRegistrationWithToken(input.confirmationToken)

      // credentials を設定
      this.credentials.update(credentials)

      output.isSuccessful = true
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: e instanceof ExpectedError ? e.message : '認証に失敗しました',
      })
    }

    return output
  }

  private getDefaultOutputData(): ConfirmRegistrationWithTokenUseCaseOutput {
    return {
      isSuccessful: false,
      error: null,
    }
  }
}
