import { action, computed, observable } from 'mobx'
import remotedev, { RemoteDevConfig } from 'mobx-remotedev'
import { inject, injectable } from 'inversify'
import {
  AddMemberInvitationUseCaseInput,
  AddMemberInvitationUseCaseOutput,
  FetchAttackListsStoreInput,
  FetchAttackListsUseCaseOutput,
  FetchCorporateInvestorCountsUseCaseOutput,
  FetchCorporateInvestorListsStoreInput,
  FetchCorporateInvestorListsUseCaseOutput,
  IAddMemberInvitationUseCase,
  IAppCredentials,
  IAttackListEntity,
  IChangeLanguageUseCase,
  IConfirmRegistrationWithTokenUseCase,
  ICorporateInvestorList,
  IErrorsStore,
  IFetchAttackListsUseCase,
  IFetchCorporateInvestorCountsUseCase,
  IFetchCorporateInvestorListsUseCase,
  IInitializeUseCase,
  IPreferences,
  ISendResetPasswordRequestUseCase,
  ISignInUseCase,
  ISignOutUseCase,
  ISignUpUseCase,
  IUpdateEmailUseCase,
  IUpdateMeUseCase,
  IUpdateMyProfileUseCase,
  IUpdatePasswordUseCase,
  IUpdatePasswordWithTokenUseCase,
  IUserMember,
  IUserProfileInputBase,
  IValidInvitationTokenUseCase,
  IViewer,
  IViewerStore,
  Language,
  OnInitializedHandler,
  ValidInvitationTokenUseCaseInput,
  ValidInvitationTokenUseCaseOutput,
} from '@/types'
import symbols from '@/symbols'
import {
  AddUserMemberUseCaseInput,
  AddUserMemberUseCaseOutput,
  FetchUserMembersUseCaseInput,
  FetchUserMembersUseCaseOutput,
  IAddUserMemberUseCase,
  IFetchUserMembersUseCase,
  IRemoveUserMemberUseCase,
  RemoveUserMemberUseCaseInput,
  RemoveUserMemberUseCaseOutput,
} from '@/types/useCases/userMembers'

const remoteDevConfig: RemoteDevConfig = {
  name: 'ViewerStore',
  global: true,
  remote: false,
}

@remotedev(remoteDevConfig)
@injectable()
export default class ViewerStore implements IViewerStore {
  @observable isInitialized = false

  @observable attackLists: IAttackListEntity[] = []

  @observable corporateInvestorLists: ICorporateInvestorList[] = []

  @observable totalCount: number

  @observable totalPages: number

  @observable currentPage: number

  @observable hasNextAttackListsPage = true

  @observable hasNextCorporateInvestorListsPage = true

  @observable userMembers: IUserMember[] = []

  @observable allCorporateInvestorsCount: string

  constructor(
    @inject(symbols.IViewer) public viewer: IViewer,
    @inject(symbols.IAppCredentials)
    private appCredentials: IAppCredentials,
    @inject(symbols.IPreferences) private preferences: IPreferences,
    @inject(symbols.IErrorsStore) private errorsStore: IErrorsStore,
    @inject(symbols.ISignInUseCase) private signInUseCase: ISignInUseCase,
    @inject(symbols.ISignUpUseCase) private signUpUseCase: ISignUpUseCase,
    @inject(symbols.IConfirmRegistrationWithTokenUseCase)
    private confirmRegistrationWithTokenUseCase: IConfirmRegistrationWithTokenUseCase,
    @inject(symbols.ISignOutUseCase) private signOutUseCase: ISignOutUseCase,
    @inject(symbols.IUpdatePasswordUseCase) private updatePasswordUseCase: IUpdatePasswordUseCase,
    @inject(symbols.IUpdatePasswordWithTokenUseCase)
    private updatePasswordWithTokenUseCase: IUpdatePasswordWithTokenUseCase,
    @inject(symbols.ISendResetPasswordRequestUseCase)
    private sendResetPasswordRequestUseCase: ISendResetPasswordRequestUseCase,
    @inject(symbols.IUpdateEmailUseCase) private updateEmailUseCase: IUpdateEmailUseCase,
    @inject(symbols.IUpdateMeUseCase) private updateMeUseCase: IUpdateMeUseCase,
    @inject(symbols.IChangeLanguageUseCase) private changeLanguageUseCase: IChangeLanguageUseCase,
    @inject(symbols.IInitializeUseCase) private initializeUseCase: IInitializeUseCase,
    @inject(symbols.IFetchAttackListsUseCase) private fetchAttackListsUseCase: IFetchAttackListsUseCase,
    @inject(symbols.IFetchCorporateInvestorListsUseCase)
    private fetchCorporateInvestorListsUseCase: IFetchCorporateInvestorListsUseCase,
    @inject(symbols.IFetchUserMembersUseCase) private fetchUserMembersUseCase: IFetchUserMembersUseCase,
    @inject(symbols.IAddUserMemberUseCase) private addUserMemberUseCase: IAddUserMemberUseCase,
    @inject(symbols.IRemoveUserMemberUseCase) private removeUserMemberUseCase: IRemoveUserMemberUseCase,
    @inject(symbols.IValidInvitationTokenUseCase) private validInvitationTokenUseCase: IValidInvitationTokenUseCase,
    @inject(symbols.IAddMemberInvitationUseCase) private addMemberInvitationUseCase: IAddMemberInvitationUseCase,
    @inject(symbols.IUpdateMyProfileUseCase) private updateMyProfileUseCase: IUpdateMyProfileUseCase,
    @inject(symbols.IFetchCorporateInvestorCountsUseCase)
    private fetchCorporateInvestorCountsUseCase: IFetchCorporateInvestorCountsUseCase
  ) {
    //
  }

  @computed get isSignedIn(): boolean {
    return this.appCredentials.isSignedIn
  }

  @computed get language(): Language {
    return this.preferences.language
  }

  @action
  _updateIsInitialized(isInitialized: boolean): void {
    this.isInitialized = isInitialized
  }

  @action
  _updateAttackLists(attackLists: IAttackListEntity[]): void {
    this.attackLists = attackLists
  }

  @action
  _updateCorporateInvestorLists(corporateInvestorLists: ICorporateInvestorList[]): void {
    this.corporateInvestorLists = corporateInvestorLists
  }

  @action
  updateHasNextAttackListsPage(hasNextAttackListsPage: boolean): void {
    this.hasNextAttackListsPage = hasNextAttackListsPage
  }

  @action
  updateHasNextCorporateInvestorListsPage(hasNextCorporateInvestorListsPage: boolean): void {
    this.hasNextCorporateInvestorListsPage = hasNextCorporateInvestorListsPage
  }

  @action
  _updateTotalCount(totalCount: number): void {
    this.totalCount = totalCount
  }

  @action
  _updateTotalPages(totalPages: number): void {
    this.totalPages = totalPages
  }

  @action
  _updateCurrentPage(currentPage: number): void {
    this.currentPage = currentPage
  }

  @action
  _updateUserMembers(userMembers: IUserMember[]): void {
    this.userMembers = userMembers
  }

  @action
  _addUserMember(userMember: IUserMember): void {
    this.userMembers.push(userMember)
  }

  @action
  _removeUserMember(id: string): void {
    this.userMembers = this.userMembers.filter((u) => u.id !== id)
  }

  @action
  _updateAllCorporateInvestorsCount(allCorporateInvestorsCount: string): void {
    this.allCorporateInvestorsCount = allCorporateInvestorsCount
  }

  async updateNames(name: string, username: string): Promise<boolean> {
    const output = await this.updateMeUseCase.handle({
      username,
      name,
      email: this.viewer.email,
    })

    if (output.error) {
      this.errorsStore.handle(output.error)

      return false
    }

    if (output.name) {
      this.viewer.updateName(output.name)
    }

    if (output.username) {
      this.viewer.updateUsername(output.username)
    }

    return true
  }

  async updateEmail(newEmail: string): Promise<boolean> {
    const output = await this.updateEmailUseCase.handle({
      email: newEmail,
      username: this.viewer.username,
      name: this.viewer.name,
    })

    if (output.error) {
      this.errorsStore.handle(output.error)

      return false
    }

    return true
  }

  async updatePassword(
    newPassword: string,
    newPasswordConfirmation: string,
    currentPassword: string
  ): Promise<boolean> {
    const output = await this.updatePasswordUseCase.handle({
      currentPassword,
      newPassword,
      newPasswordConfirmation,
    })

    if (output.error) {
      this.errorsStore.handle(output.error)

      return false
    }

    return true
  }

  async updatePasswordWithToken(
    resetPasswordToken: string,
    password: string,
    passwordConfirmation: string
  ): Promise<boolean> {
    const output = await this.updatePasswordWithTokenUseCase.handle({
      resetPasswordToken,
      password,
      passwordConfirmation,
    })

    if (output.error) {
      this.errorsStore.handle(output.error)

      return false
    }

    return true
  }

  async sendResetPasswordRequest(email: string): Promise<boolean> {
    const output = await this.sendResetPasswordRequestUseCase.handle({ email })
    if (output.error) {
      this.errorsStore.handle(output.error)

      return false
    }

    return true
  }

  async signIn(email: string, password: string): Promise<boolean> {
    const output = await this.signInUseCase.handle({
      email,
      password,
    })

    if (output.error) {
      this.errorsStore.handle(output.error)

      return false
    }

    return true
  }

  async confirmRegistrationWithToken(confirmationToken: string): Promise<boolean> {
    const output = await this.confirmRegistrationWithTokenUseCase.handle({
      confirmationToken,
    })

    if (output.error) {
      this.errorsStore.handle(output.error)

      return false
    }

    return true
  }

  async signUp(
    name: string,
    username: string,
    email: string,
    password: string,
    passwordConfirmation: string,
    companyName: string,
    jobTitle: string,
    token?: string
  ): Promise<boolean> {
    const output = await this.signUpUseCase.handle({
      name,
      username,
      email,
      password,
      passwordConfirmation,
      companyName,
      jobTitle,
      token,
    })
    if (!output.isSuccessful) {
      this.errorsStore.handle(output.error)

      return false
    }

    return true
  }

  async signOut(): Promise<boolean> {
    const output = await this.signOutUseCase.handle()
    if (!output.isSuccessful) {
      return false
    }

    return true
  }

  async initialize(language: Language, onInitialized?: OnInitializedHandler): Promise<void> {
    // クレデンシャルのリストアと viewer の更新
    const output = await this.initializeUseCase.handle({
      language,
    })

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    // this.initialized を true にする前にリダイレクトとかをするためのコールバックを実行
    if (onInitialized) {
      await onInitialized({
        isSignedIn: this.isSignedIn,
        username: this.viewer.username, // ログインしてなければ null
      })
    }

    this._updateIsInitialized(true)
  }

  async changeLanguage(language: Language): Promise<boolean> {
    const output = await this.changeLanguageUseCase.handle({
      language,
    })

    if (output.isSuccessful) {
      return true
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return false
  }

  // TODO: fetchCorporateInvestorLists に置き換えたので削除
  async fetchAttackLists(input: FetchAttackListsStoreInput): Promise<FetchAttackListsUseCaseOutput> {
    const output = await this.fetchAttackListsUseCase.handle(input)

    if (output.isSuccessful) {
      this._updateAttackLists(output.data.attackLists)

      this._updateTotalCount(output.data.totalCount)
      this._updateTotalPages(output.data.totalPages)
      this._updateCurrentPage(output.data.currentPage)
      this.updateHasNextAttackListsPage(output.data.hasNextPage)
    } else {
      this._updateAttackLists([])
    }

    return output
  }

  async fetchCorporateInvestorLists(
    input: FetchCorporateInvestorListsStoreInput
  ): Promise<FetchCorporateInvestorListsUseCaseOutput> {
    const output = await this.fetchCorporateInvestorListsUseCase.handle(input)

    if (output.isSuccessful) {
      this._updateCorporateInvestorLists(output.data.corporateInvestorLists)

      this._updateTotalCount(output.data.totalCount)
      this._updateTotalPages(output.data.totalPages)
      this._updateCurrentPage(output.data.currentPage)
      this.updateHasNextCorporateInvestorListsPage(output.data.hasNextPage)
    } else {
      this._updateCorporateInvestorLists([])
    }

    return output
  }

  async fetchUserMembers(input: FetchUserMembersUseCaseInput): Promise<FetchUserMembersUseCaseOutput> {
    const output = await this.fetchUserMembersUseCase.handle(input)

    if (output.isSuccessful) {
      this._updateUserMembers(output.data.userMembers)
    } else {
      this._updateUserMembers([])
    }

    return output
  }

  async addUserMember(input: AddUserMemberUseCaseInput): Promise<AddUserMemberUseCaseOutput> {
    const output = await this.addUserMemberUseCase.handle(input)

    if (output.isSuccessful) {
      this._addUserMember(output.data.userMember)
    }

    return output
  }

  async removeUserMember(input: RemoveUserMemberUseCaseInput): Promise<RemoveUserMemberUseCaseOutput> {
    const output = await this.removeUserMemberUseCase.handle(input)

    if (output.isSuccessful) {
      this._removeUserMember(output.data.userMember.id)
    }

    return output
  }

  async validInvitationToken(input: ValidInvitationTokenUseCaseInput): Promise<ValidInvitationTokenUseCaseOutput> {
    const output = await this.validInvitationTokenUseCase.handle(input)

    return output
  }

  async addMemberInvitation(input: AddMemberInvitationUseCaseInput): Promise<AddMemberInvitationUseCaseOutput> {
    const output = await this.addMemberInvitationUseCase.handle({
      targetUsername: input.targetUsername,
      days: input.days || 7,
      email: input.email,
      role: input.role,
    })

    return output
  }

  isExistUserMember(username: string): boolean {
    return this.userMembers.some((um) => um.member.username === username)
  }

  // ゲストユーザーが投資会社を１つしか見れないようにするための設定
  hasViewedCorporateInvestorBefore(slug: string): boolean {
    return this.appCredentials.hasViewedCorporateInvestorBefore(slug)
  }

  async updateMyProfile(profile: IUserProfileInputBase): Promise<boolean> {
    const output = await this.updateMyProfileUseCase.handle({
      profile,
    })

    if (output.error) {
      this.errorsStore.handle(output.error)

      return false
    }

    if (output.profile) {
      this.viewer.updateProfile(output.profile)
    }

    return true
  }

  async fetchCorporateInvestorCounts(): Promise<FetchCorporateInvestorCountsUseCaseOutput> {
    const output = await this.fetchCorporateInvestorCountsUseCase.handle()

    if (output.isSuccessful) {
      this._updateAllCorporateInvestorsCount(
        output.data.corporateInvestorCounts.dashboardItems[0].numberDashboardItem.value
      )
    }

    return output
  }
}
