import { action, observable } from 'mobx'
import {
  EntityTypeBase,
  IAngelBase,
  IAttackListEntity,
  IAttackListEntityBase,
  ICorporateInvestorBase,
  IErrorsStore,
} from '@/types'

export default class AttackListEntity implements IAttackListEntity {
  @observable about = ''

  @observable entityType: EntityTypeBase

  @observable interviewStatus = null

  @observable mailStatus = null

  @observable investInLps = null

  @observable investmentPurpose = null

  @observable investmentTargetRegion = null

  @observable isListed = false

  @observable kind = null

  @observable listId = ''

  @observable logo = ''

  @observable name = ''

  @observable slug = ''

  @observable portfolioCount = 0

  @observable isVerified: boolean

  @observable notes = ''

  @observable inquiryStatus = null

  @observable isHighResponseRate = false

  errorsStore: IErrorsStore

  @action
  _mapFromBase(base: IAttackListEntityBase): void {
    const keys = Object.keys(base)
    keys.forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key] = base[key]
    })
  }

  @action
  update(base: IAttackListEntityBase): void {
    this._mapFromBase(base)
  }

  constructor(base: IAttackListEntityBase) {
    this._mapFromBase(base)
  }

  @action
  updateFromAngel(base: IAngelBase): void {
    this.isListed = base.isListed
  }

  @action
  updateFromCorporateInvestor(base: ICorporateInvestorBase): void {
    this.isListed = base.isListed
  }
}
