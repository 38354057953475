import { v4 as uuidv4 } from 'uuid'
import {
  AxiosGraphQLError,
  GraphQLError,
  InquiryStatusBase,
  InterviewStatusBase,
  InvestInLps,
  InvestmentPurpose,
  InvestmentTargetRegion,
  Kind,
  LeadStatusBase,
  MailStatusBase,
} from '@/types'

/**
 * @param ms
 */
export function sleep(ms: number): Promise<void> {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve()
    }, ms)
  })
}

/**
 * UUIDを生成して返す
 */
export function getUUID(): string {
  return uuidv4()
}

/**
 * ブラウザかどうか判定
 */
export function isBrowser(): boolean {
  // window が定義されている場合は browser
  return typeof window !== 'undefined'
}

/**
 * 文字列を受け取って数値を返す（少数に対応）
 * @param str
 * @param defaultValue
 */
export function getValidFloat(str: string, defaultValue = 0): number {
  const value = parseFloat(str)
  // NaN の場合は `defaultValue` を返す
  // ちなみに空文字は NaN
  if (Number.isNaN(value)) {
    return defaultValue
  }

  return value
}

/**
 * オープンリダイレクトにならないパスを返す
 * @param url
 */
export function getValidRedirectPath(url: string): string {
  // `//` から始まる URL の場合はルートパスを返す
  const regex1 = new RegExp('^//.*')
  const found1 = regex1.exec(url)
  if (found1) {
    return '/'
  }

  // `//` から始まる以外で `/` から始まるパスはそのまま返す
  const regex2 = new RegExp('^/.*')
  const found2 = regex2.exec(url)
  if (found2) {
    return url
  }

  // それ以外の場合はルートパスを返す
  return '/'
}

/**
 * 現在のパスの情報をつけてサインインページのパスを返す
 * @param currentPath
 */
export function getSignInPath(currentPath: string): string {
  if (currentPath === '/' || currentPath === '/signin' || currentPath === '/signup') {
    return '/signin'
  }

  const path = getValidRedirectPath(currentPath)
  return `/signin?redirect_to=${path}`
}

/**
 * id の配列を昇順にしてつなげた文字列を返す
 * ページ送り管理などのオブジェクトのキーに利用
 * e.g., [2,3,1] -> "1,2,3"
 * @param ids
 * @param defaultValue
 */
export function getHashedIds(ids: string[], defaultValue = 'all'): string {
  // 重複削除
  const uniqueIds = getUniqueArray(ids)
  // 昇順ソート
  uniqueIds.sort((a, b) => Number(a) - Number(b))
  // toString して空文字になるなら defaultValue を返す
  return uniqueIds.toString() || defaultValue
}

/**
 * 要素が重複していない配列を返す
 * @param rawArray
 */
export function getUniqueArray<T>(rawArray: T[]): T[] {
  return Array.from(new Set(rawArray))
}

/**
 * Axios のエラーから GraphQL の最初のエラーを取り出して利用頻度の高い message や code を含めたタプルを返す
 * @param e
 */
export function getGraphQLErrorInfo(e: AxiosGraphQLError): [GraphQLError, string, string] {
  const graphqlError = e?.response?.errors[0]
  if (!graphqlError) {
    return [null, null, null]
  }

  return [graphqlError, graphqlError.message, graphqlError?.extensions?.code]
}

/**
 * base64の文字列を BASE64_IMAGE に変更する
 * 文字列を受け取って文字列を返す
 * @param str
 */
export function filterBase64(str: string): string {
  if (!str) {
    return str
  }

  const REG_BASE64 = /data:image\/\w*;base64,\S*/
  return str.replace(REG_BASE64, 'FILTERED_BASE64_IMAGE')
}

/**
 * 文字列を受け取って
 * @param str
 * @param defaultValue
 */
export function getValidKind(str: string, defaultValue: Kind = null): Kind {
  switch (str) {
    case Kind.CORPORATION:
      return Kind.CORPORATION
    case Kind.LISTED_CORPORATION:
      return Kind.LISTED_CORPORATION
    case Kind.CVC:
      return Kind.CVC
    case Kind.FAMILY_OFFICE:
      return Kind.FAMILY_OFFICE
    case Kind.FOREIGN_VC:
      return Kind.FOREIGN_VC
    case Kind.OTHER:
      return Kind.OTHER
    case Kind.VC:
      return Kind.VC
    default:
      return defaultValue
  }
}

/**
 * 文字列を受け取って
 * @param str
 * @param defaultValue
 */
export function getValidInvestInLps(str: string, defaultValue: InvestInLps = null): InvestInLps {
  switch (str) {
    case InvestInLps.UNKNOWN:
      return InvestInLps.UNKNOWN
    case InvestInLps.NO:
      return InvestInLps.NO
    case InvestInLps.YES:
      return InvestInLps.YES
    default:
      return defaultValue
  }
}

/**
 * 文字列を受け取って
 * @param str
 * @param defaultValue
 */
export function getValidLeadStatuses(str: string, defaultValue: LeadStatusBase = null): LeadStatusBase {
  switch (str) {
    case LeadStatusBase.UNKNOWN:
      return LeadStatusBase.UNKNOWN
    case LeadStatusBase.NO:
      return LeadStatusBase.NO
    case LeadStatusBase.YES:
      return LeadStatusBase.YES
    default:
      return defaultValue
  }
}

/**
 * 文字列を受け取って
 * @param str
 * @param defaultValue
 */
export function getValidInvestmentPurpose(str: string, defaultValue: InvestmentPurpose = null): InvestmentPurpose {
  switch (str) {
    case InvestmentPurpose.UNKNOWN:
      return InvestmentPurpose.UNKNOWN
    case InvestmentPurpose.NET_INVESTMENT:
      return InvestmentPurpose.NET_INVESTMENT
    case InvestmentPurpose.OPEN_INNOVATION:
      return InvestmentPurpose.OPEN_INNOVATION
    default:
      return defaultValue
  }
}

/**
 * 文字列を受け取って
 * @param str
 * @param defaultValue
 */
export function getValidInvestmentTargetRegion(
  str: string,
  defaultValue: InvestmentTargetRegion = null
): InvestmentTargetRegion {
  switch (str) {
    case InvestmentTargetRegion.GLOBAL:
      return InvestmentTargetRegion.GLOBAL
    case InvestmentTargetRegion.JAPAN:
      return InvestmentTargetRegion.JAPAN
    case InvestmentTargetRegion.UNKNOWN:
      return InvestmentTargetRegion.UNKNOWN
    default:
      return defaultValue
  }
}

/**
 * 文字列を受け取って
 * @param str
 * @param defaultValue
 */
export function getValidInterviewStatus(str: string, defaultValue: InterviewStatusBase = null): InterviewStatusBase {
  if (str) {
    return str as InterviewStatusBase
  }

  return defaultValue
}

/**
 * 文字列を受け取って
 * @param str
 * @param defaultValue
 */
export function getValidMailStatus(str: string, defaultValue: MailStatusBase = null): MailStatusBase {
  if (str) {
    return str as MailStatusBase
  }

  return defaultValue
}

/**
 * 文字列を受け取って
 * @param str
 * @param defaultValue
 */
export function getValidInquiryStatus(str: string, defaultValue: InquiryStatusBase = null): InquiryStatusBase {
  if (str) {
    return str as InquiryStatusBase
  }
  return defaultValue
}

/**
 * 特定のオブジェク配列から id の配列を作成
 * @param values
 */
export function getIdsArray<T extends { id: string }>(values: T[] | null = null): string[] {
  if (!values) {
    return []
  }
  return values.map((value) => value.id)
}

/**
 * ページ数から after に使用する値を算出
 * @param page
 * @param limit
 */
export function calcAfterValue(page: number, limit: number): string {
  return ((page - 1) * limit).toString()
}

/**
 * currentPage を算出
 * @param startCursor
 * @param limit
 */
export function calcCurrentPage(startCursor: string, limit: number): number {
  return Math.ceil(Number(startCursor) / limit)
}
