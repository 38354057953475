import { GraphQLResponse } from 'graphql-request/dist/types'
import {
  AddedListBase,
  AngelBase,
  AngelListInputBase,
  AttackListEntityBase,
  BillingPortalBase,
  CommentBase,
  CompanyBase,
  CorporateInvestorBase,
  CorporateInvestorListInputBase,
  CreateCheckoutSessionInputBase,
  DashboardBase,
  DashboardItemBase,
  DbSubscriptionInputBase,
  InvitationBase,
  InvitationSentBase,
  LocationBase,
  MarketBase,
  MeBase,
  PaymentIntentBase,
  CheckoutSessionBase,
  RemoveAttackListInputBase,
  RemovedListBase,
  RoundBase,
  UserBase,
  UserMemberBase,
  UserMemberRole,
  UserProfileBase,
  UserProfileInputBase,
  CorporateInvestorListBase,
  ExternalLinkBase,
} from '@/lib/generated/sdk'
import {
  AddAngelListsUseCaseInput,
  AddAngelListsUseCaseOutput,
  AddCorporateInvestorListsUseCaseIntput,
  AddCorporateInvestorListsUseCaseOutpt,
  FetchDashboardUseCaseInput,
  FetchDashboardUseCaseOutput,
  RemoveAttackListsUseCaseInput,
  RemoveAttackListsUseCaseOutput,
  UpdateUserMemberUseCaseOutput,
} from './useCases'

export * from '@/lib/generated/sdk'

export type Primitive = number | string | boolean | bigint | symbol | null | undefined

export type IOriginalErrorInstance = Error & {
  response?: GraphQLResponse
  extra?: Record<string, unknown>
}

export interface IBaseAppError {
  // エラー集計などに使う際のための箱を用意
  originalInstance: null | IOriginalErrorInstance
  // UIに表示したいエラーメッセージがあれば設定する
  messageForUI: null | string
  code?: ErrorCode
  tags?: {
    [key: string]: Primitive
  }
  extra?: Record<string, unknown>
}

export enum ErrorCode {
  DEFAULT = 'DEFAULT',
  NO_SUBSCRIPTION_ERROR = 'NO_SUBSCRIPTION_ERROR',
  STRIPE_INVALID_REQUEST = 'STRIPE_INVALID_REQUEST',
  STRIPE_CURRENCY_MISMATCH = 'STRIPE_CURRENCY_MISMATCH',
  STRIPE_INVALID_PROMOTION_CODE_NOT_FIRST_TIME_PAYMENT = 'STRIPE_INVALID_PROMOTION_CODE_NOT_FIRST_TIME_PAYMENT',
  NOT_AUTHORIZED_ERROR = 'NOT_AUTHORIZED_ERROR',
  NOT_SIGNED_IN = 'NOT_SIGNED_IN',
  EXISTING_RECORD_ERROR = 'EXISTING_RECORD_ERROR',
  ACTIVE_RECORD_ERROR = 'ACTIVE_RECORD_ERROR',
}

export interface IAppError extends IBaseAppError {
  code: ErrorCode
  message: string
}

export interface IUseCaseOutput<T = any> {
  data: T
  error: null | IAppError
  isSuccessful: boolean
}

export interface IAsyncUseCase<I, O> {
  handle(input: I): Promise<IUseCaseOutput<O>>
}

export interface IAppCredentialsBase {
  accessToken: string
  client: string
  expiry: number
  tokenType: string
  uid: string
}

export interface IAppCredentials extends IAppCredentialsBase {
  isSignedIn: boolean

  update(base: IAppCredentialsBase): void
  updateAccessToken(accessToken: string): void
  restore(): boolean
  sync(): void
  clear(): void
  getLatestCredentials(): IAppCredentialsBase
  storeCorporateInvestorSlug(slug: string): void
  hasViewedCorporateInvestorBefore(slug: string): boolean
}

export enum MessageType {
  Info,
  Error,
}

export enum MessageState {
  Visible,
  Invisible,
}

export interface IMessageBase {
  key?: string
  type: MessageType
  body: string
  ttl?: number // 表示時間（msec）
  isTranslated?: boolean
  isDismissable?: boolean
  state?: MessageState
}

export interface IMessage extends IMessageBase {
  hide(): void
}

export enum Language {
  JA = 'ja',
  EN = 'en',
}

export interface IPreferencesBase {
  language: Language
}

export interface IPreferences extends IPreferencesBase {
  restore(): boolean

  changeLanguage(language: Language): void

  update(base: IPreferencesBase): void
}

export type IViewerBase = MeBase

export type IUserBase = UserBase

export type IUserProfileInputBase = UserProfileInputBase
export type IUserProfileBase = UserProfileBase
export type IUserProfile = IUserProfileBase

export type ICorporateInvestorListInputBase = CorporateInvestorListInputBase

export type IAngelListInputBase = AngelListInputBase

export type IDashboardBase = DashboardBase

export type IDashboardItemBase = DashboardItemBase

export interface IViewer extends IViewerBase {
  dashboard: IDashboardBase

  isVC: boolean

  hasActiveDbSubscription: boolean

  update(base: IViewerBase): void

  updateProfile(profile: IUserProfile): void

  updateName(name: string): void

  updateUsername(username: string): void

  updateUserMemberRole(targetUsername: string, role: UserMemberRole): void

  // TODO: CorporateInvestor に移動
  toggleCorporateInvestorList(slug: string, targetUsername: string): Promise<ICorporateInvestorBase>

  // TODO: Angel に移動
  toggleAngelList(slug: string, targetUsername: string): Promise<IAngelBase>

  addCorporateInvestorLists(
    input: AddCorporateInvestorListsUseCaseIntput
  ): Promise<AddCorporateInvestorListsUseCaseOutpt>

  addAngelLists(input: AddAngelListsUseCaseInput): Promise<AddAngelListsUseCaseOutput>

  // TODO: AttackListEntity に移動
  updateCorporateInvestorList(
    id: string,
    input: ICorporateInvestorListInputBase,
    targetUsername: string
  ): Promise<ICorporateInvestorListBase>

  // TODO: AttackListEntity に移動
  updateAngelList(id: string, input: IAngelListInputBase, targetUsername: string): Promise<IAttackListEntityBase>

  getAttackListOwner(targetUsername: string): IUserBase

  showableAttackList(targetUsername: string): boolean

  settingableAttackList(targetUsername: string): boolean

  fetchDashboard(input: FetchDashboardUseCaseInput): Promise<FetchDashboardUseCaseOutput>

  addCommentToCorporateInvestor(slug: string, body: string): Promise<ICommentBase>

  updateCommentToCorporateInvestor(id: string, body: string): Promise<ICommentBase>

  removeCommentToCorporateInvestor(id: string): Promise<ICommentBase>

  addCommentToAngel(slug: string, body: string): Promise<CommentBase>

  updateCommentToAngel(id: string, body: string): Promise<ICommentBase>

  removeCommentToAngel(slug: string): Promise<ICommentBase>

  removeAttackLists(input: RemoveAttackListsUseCaseInput): Promise<RemoveAttackListsUseCaseOutput>

  createBillingPortalSession(): Promise<BillingPortalBase>

  createDbSubscription(input: DbSubscriptionInputBase): Promise<PaymentIntentBase>

  createCheckoutSession(input: CreateCheckoutSessionInputBase): Promise<CheckoutSessionBase>
}

// ============================================================
// CorporateInvestor
// ============================================================
export type ICorporateInvestorBase = CorporateInvestorBase
export type IAddedListBase = AddedListBase

export interface ICorporateInvestor extends ICorporateInvestorBase {
  update(base: ICorporateInvestorBase): void
}

// ============================================================
// Angel
// ============================================================
export type IAngelBase = AngelBase

export interface IAngel extends IAngelBase {
  update(base: IAngelBase): void
}

// ============================================================
// Round
// ============================================================
export type IRoundBase = RoundBase
export type IRound = IRoundBase

// ============================================================
// Company
// ============================================================
export type ICompanyBase = CompanyBase

// ============================================================
// Market
// ============================================================
export type IMarketBase = MarketBase
export type IMarket = IMarketBase

// ============================================================
// Locations
// ============================================================
export type ILocationBase = LocationBase
export type ILocation = ILocationBase

// ============================================================
// AttackListEntity
// ============================================================
export type IAttackListEntityBase = AttackListEntityBase

export interface IAttackListEntity extends IAttackListEntityBase {
  update(base: IAttackListEntityBase): void
  updateFromAngel(base: IAngelBase): void
  updateFromCorporateInvestor(base: ICorporateInvestorBase): void
}

export type IRemovedListBase = RemovedListBase
export type IRemoveAttackListInputBase = RemoveAttackListInputBase

// ============================================================
// CorporateInvestorList
// ============================================================
export type ICorporateInvestorListBase = CorporateInvestorListBase

export interface ICorporateInvestorList extends ICorporateInvestorListBase {
  update(base: ICorporateInvestorListBase): void
}

// ============================================================
// UserMember
// ============================================================
export type IUserMemberBase = UserMemberBase

export interface IUserMember extends IUserMemberBase {
  update(base: IUserMemberBase): void
  save(role: UserMemberRole, targetUsername: string): Promise<UpdateUserMemberUseCaseOutput>
}

// ============================================================
// Comment
// ============================================================
export type ICommentBase = CommentBase
export interface IComment extends ICommentBase {
  save(id: string, body: string): Promise<boolean>
}

// ============================================================
// Invitation
// ============================================================
export type IInvitationBase = InvitationBase
export type IInvitationSentBase = InvitationSentBase

// ============================================================
// Subscription
// ============================================================
export type PaymentInfo = {
  couponCode: string
  userName: string
  division: string
  position: string
  email: string
  phoneNumber: string
}

// ============================================================
// ExternalLink
// ============================================================
export type IExternalLinkBase = ExternalLinkBase
